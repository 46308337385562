import React from "react"
import BtfData from "./data/bf.json"
import Styles from "./css/bf.module.scss"
import {isChrome,isFirefox,isIeEdge,isSafari, getBrowser} from "@tightrope/lpscripts/browserdetect"

class belowFold extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: 'none',
      pageData: props.data,
      data: BtfData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
   else if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
   else if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
   else if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
   else if (getBrowser() === "safari" && browsers.includes("safari")) {
      display = true;
    }
   else if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();
    this.state.isSafari = isSafari();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
      if (isSafari()) {
        const browserData = Object.assign(this.state.data, this.state.data.safari);
        this.setState({ data: browserData });
      }
      if (isIeEdge()) {
        const browserData = Object.assign(this.state.data, this.state.data.edge);
        this.setState({ data: browserData });
      }

      if (typeof window != "undefined") {
      window.mapParams();
    }
    });
  }

  render() {
    return (
      <section style={{ display: this.state.display }}>
      <div className = {Styles.belowFold}>
      <section className="what-we-do">
            <div className="container">
              <h2>{this.state.data.btfMissionHeadline}</h2>
              <div className="mission-content">
                <p>{this.state.data.btfMissionStatement}</p>
              </div>
            </div>
          </section>

          <section className="benefits">
            <div className="container">
              <div className="benefits-grid">
                <div className="benefit-card">
                  <div className="icon">
                  </div>
                  <h3>{this.state.data.btfBenefitsHeadline1}</h3>
                  <p>{this.state.data.btfBenefitsCopy1}</p>
                  <p>{this.state.data.btfBenefitsCopy2}</p>
                </div>
                <div className="benefit-card">
                  <div className="icon">
                  </div>
                  <h3>{this.state.data.btfBenefitsHeadline2}</h3>
                  <p>{this.state.data.btfBenefitsCopy3}</p>
                  <p>{this.state.data.btfBenefitsCopy4}</p>
                </div>
                <div className="benefit-card">
                  <div className="icon">
                  </div>
                  <h3>{this.state.data.btfBenefitsHeadline3}</h3>
                  <p>{this.state.data.btfBenefitsCopy5}</p>
                  <p>{this.state.data.btfBenefitsCopy6}</p>
                </div>
              </div>
            </div>
          </section>

          <section className="download-section">
            <div className="container">
              <h2>{this.state.data.btfDownloadHeadline1}</h2>
              <div className="download-content">
                <a
                  role="button"
                  className="cta-button primary"
                  onClick={() => { window.triggerInstall() }}
                  data-cy="cta"
                >
                  {this.state.data.cta}
                </a>
                <div className="download-disclosure">
                {this.props.children}
                </div>
              </div>
            </div>
          </section>
          </div>
          {this.props.footer}
      </section>

    )
  }
}

export default belowFold
